export class AppConstant {
  LOGO_IMAGE = "https://in.uplonline.com/images/logo.png";
  DEFAULT_USER_IMAGE = "assets/img/default-user.png";
}

export class MachineConstants extends AppConstant {
  FETCH_MACHINE_ERROR = "Error while fetching Machine";
  ASSIGN_MACHINE_SUCCESS = "Machine assigned successfully";
  UNASSIGN_MACHINE_SUCCESS = "Machine un-assigned successfully";
  UNASSIGN_MACHINE_ERROR = "Error while un-assigning Machine";
  ASSIGN_MACHINE_ERROR = "Error while assigning Machine";
  UPDATE_MACHINE_SUCCESS = "Machine details updated successfully";
  UPDATE_CONTROLLER_SUCCESS = "Controller details updated successfully";
  UPDATE_MACHINE_ERROR = "Error while updating Machine";
  UPDATE_CONTROLLER_ERROR = "Error while updating Controller details";
  SUCCESS_ADD_CONTROLLER = "Controller added successfully";
  ERROR_ADD_CONTROLLER = "Error while adding controller";
  MACHINE_CREATED = "Machine created successfully";
  CREATE_MACHINE_ERROR = "Error while creating Machine";
  DELETE_MACHINE_SUCCESS = "Machine deleted Successfully";
  DELETE_MACHINE_ERROR = "Error while deleting Machine";
  NO_MACHINE_BREAKDOWN_FOUND = "No Machines breakdown found";
  NO_MACHINE_BREAKDOWN_FOUND_WITH_STATUS =
    "No Machines breakdown found for the current status";
  FILE_FORMAT_ERROR = "Invalid File type";
  FILE_UPLOAD_SUCCESS = "File uploaded successfully";
  FILE_UPLOAD_ERROR = "Error while uploading the file";
  FILE_CHOOSE_INTERNET_ERROR =
    "Error while uploading the file. Please check your Internet Connection";
  MACHINE_HANDOVER_SUCCESS = "Machine Handover added successfully";
  MACHINE_HANDOVER_ERROR = "Error while adding Machine Handover";
  INSPECTION_FETCH_ERROR = "Error while fetching Inspections";
  FILE_FORMAT_TYPE_ERROR =
    "Invalid File format. Please upload Image in PNG, JPG and JPEG format only";
  FILE_UPLOAD_ERROR_TYPE = "Error while uploading the Image";

  S3_DOCUMENTS_DIRECTORIES = [
    {
      path: "machine-document-upload/battery-warrenty/",
      title: "Upload Battery Warranty",
      label: "Battery Warranty",
    },
    {
      path: "machine-document-upload/invoice/",
      title: "Upload Invoice",
      label: "Invoice",
    },
    {
      path: "machine-document-upload/insurance/",
      title: "Upload Insurance",
      label: "Insurance",
    },
    {
      path: "machine-document-upload/rc/",
      title: "Upload RC",
      label: "RC",
    },
    {
      path: "machine-document-upload/temporary-registration/",
      title: "Upload Temporary Registration",
      label: "Temporary Registration",
    },
    {
      path: "machine-document-upload/other-documents/",
      title: "Upload Other Documents",
      label: "Others",
    },
  ];

  S3_MACHINE_DOCUMENT_DIRECTORIES = {
    machine_images: {
      path: "machine-document-upload/",
    },
  };
}

export class UserConstants extends AppConstant {
  OTP_SENT_SUCCESS = "The OTP has been sent to your mobile number";
  OTP_SENT_ERROR = "Error while sending OTP to your mobile number";
  LOGIN_SUCCESS = "Login successful";
  LOGIN_ERROR = "Error while Login";
  DELETE_SUCCESS = "User deleted successfully";
  DELETE_FAIL = "Error while deleting user";
  FETCH_USER_ERROR = "Error while fetching user details";
  USER_CREATE_SUCCESS = "User created successfully";
  USER_UPDATE_SUCCESS = "User updated successfully";
  USER_UPDATE_ERROR = "Error while updating user";
  USER_CREATE_ERROR = "Error while creating user";
  FILE_ATTACH_SUCCESS = "File attached successfully";
  FILE_ATTACH_ERROR = "Please attach excel file only";
  FILE_ATTACH_SIZE_ERROR = "Please attach excel file size upto 10 MB";
  OPERATOR_UPLOAD_SUCCESS = "Operators uploaded successfully";
  OPERATOR_UPLOAD_ERROR = "Error while uploading Operators";
  USER_ACTIVE_STATUS_SUCCESS = "User activated successfully";
  USER_INACTIVE_STATUS_SUCCESS = "User deactivated successfully";
  FILE_UPLOAD_SUCCESS = "File uploaded successfully";
  FILE_UPLOAD_ERROR = "Error while uploading the file";
  FILE_CHOOSE_INTERNET_ERROR =
    "Error while uploading the file. Please check your Internet Connection";
  FILE_SIZE_ERROR = "File should be less than 2 MB";
  FILE_FORMAT_ERROR = "Invalid File type";

  S3_DOCUMENTS_DIRECTORIES = [
    // {
    //     path: 'users_kyc_documents/aadhaar/',
    //     title: 'Upload Aadhaar Card',
    //     label: 'Aadhaar Card',
    //     isValidated: {
    //         key: 'isAdhaarCardValidated',
    //         value: false,
    //     }
    // },
    {
      path: "users_kyc_documents/pancard/",
      title: "Upload PAN Card",
      label: "PAN Card",
      isValidated: {
        key: "isPanCardValidated",
        value: false,
      },
    },
    {
      path: "users_kyc_documents/drivinglicense/",
      title: "Upload Driving Licence Card",
      label: "Driving Licence Card",
      isValidated: {
        key: "isDrivingLicenseValidated",
        value: false,
      },
      comment: {
        key: "validationComments",
        value: "",
      },
    },
    {
      path: "users_kyc_documents/selfie/",
      title: "Upload Selfie",
      label: "Selfie",
      isValidated: {
        key: "isSelfieValidated",
        value: false,
      },
      comment: {
        key: "validationComments",
        value: "",
      },
    },
  ];
}

export class AllUserConstants {
  static USER_ACTIVE_STATUS_SUCCESS = "User activated successfully";
  static USER_INACTIVE_STATUS_SUCCESS = "User deactivated successfully";
  static DELETE_FAIL = "Error while deleting user";
  static MACHINE_DELETE_SUCCESS = "Machine deleted Successfully";
  static MACHINE_DELETE_ERROR = "Error while deleting Machine";
  static UPLOAD_AADHAR_MEDIA = "Please upload Aadhar document";
  static UPLOAD_PAN_MEDIA = "Please upload PAN document";
  static UPLOAD_DL_MEDIA = "Please upload Driving Licence document";
  static ENTER_AADHAR_NUMBER = "Please enter Aadhar Number";
  static ENTER_PAN_NUMBER = "Please enter PAN Number";
  static ENTER_DL_NUMBER = "Please enter Driving Licence Number";
}

export class GeographyConstants {
  static GEOGRAPHY_ADDED = "Geography added successfully";
  static GEOGRAPHY_UPDATE = "Geography updated successfully";
  static GEOGRAPHY_UPDATE_ERROR = "Error while updating Geography";
  static GEOGRAPHY_ADD_FAIL = "Error while adding Geography";
  static GEOGRAPHY_DELETED = "Geography deleted successfully";
  static DELETE_FAIL = "Error while deleting Geography";
  static GEOGRAPHY_TYPE = {
    zoneGeoType: "1",
    stateGeoType: "2",
    districtGeoType: "3",
    tehsilGeoType: "4",
    villageGeoType: "5",
  };
}

export class ReportsConstants {
  static NO_DATA_FOUND = "No data found";
  static NO_DATA_TO_EXPORT = "No data found to export";
  static ERROR_DATA_TO_EXPORT = "Error while exporting report";
  static SUCCESS_DATA_EXPORT = "Data exported successfully";
  static SELECT_DATE = "Please select Start and End Date";
  static SELECT_VALID_DATE = "Please select Start and End Date";
  static SALARY_UPDATE_SUCCESSFUL = "Salary details updated successfully";
  static MACHINE_REPORT_UPDATE_SUCCESSFUL =
    "Machine details updated successfully";
  static MACHINE_REPORT_UPDATE_FAIL = "Error while updating machine details";
  static SALARY_UPDATE_FAIL = "Error while updating salary details";
  static MACHINE_MAINTENANCE_REQUEST_SUCCESS =
    "Machine maintenance request created successfully";
  static MACHINE_MAINTENANCE_REQUEST_FAIL =
    "Error while creating machine maintenance request";
  static ENTER_MAINTENANCE_DESCRIPTION =
    "Please enter machine maintenance description";
  static MAX_FILE_LIMIT = "Can not upload more than 10 files at once";
  static PER_PAGE = 35;
  static CURRENT_PAGE = 1;
  static FILE_ATTACH_ERROR = "Please upload file in xlsx format only.";
  static FILE_ERROR = "Please upload file in valid format only.";
  static FILE_ATTACH_SIZE_ERROR =
    "Please attach xlsx format excel file size upto 10 MB";
  static FILE_UPLOAD_SUCCESS = "File uploaded successfully";
  static FILE_UPLOAD_ERROR = "Error while uploading the file";
  static FILE_UPLOAD_FORMAT_ERROR =
    "The Format used to Import file is incorrect. " +
    "Please Download/Export a file to refer the correct format.";
  static EXPORT_TO_EMAIL_SUCCESS =
    "Your request to export the report to email is completed. " +
    "Please check your email.";
  static EXPORT_TO_EMAIL_ERROR = "Error while exporting reports";
  static NO_DATA_FOUND_TO_EXPORT = "No data found to export";
  static NO_SPRAYING_PATTERN_FOUND =
    "No spraying pattern found for this spraying";
  static NO_SPRAYING_PATTERN_FOUND_DATE_RANGE =
    "No spraying pattern found for the selected date range";
  static MY_MOMENT_DATE_TIME_FORMATS: any = {
    parseInput: "MMM-YYYY",
    fullPickerInput: "l LT",
    datePickerInput: "MMM-YYYY",
    timePickerInput: "LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  };
  static SAMPLE_REPORT_TEMPLATE =
    "https://afs-excel-report-bucket.s3.amazonaws.com/Sample_BDO_acres_report.xlsx";
}

export class BookingConstants {
  static BOOKING_CREATED = "Booking created successfully";
  static BOOKING_ASSIGNED = "Booking assigned successfully";
  static BOOKING_VERIFIED_SUCCESS = "Booking verified successfully";
  static BOOKING_VERIFIED_ERROR = "Error while booking verification";
  static BOOKING_CREATE_FAIL = "Error while assigning Booking";
  static BOOKING_MARKED_INVALID = "Booking marked invalid successfully";
  static BOOKING_MARKED_INVALID_FAILED =
    "Error while marking Booking as invalid";
  static SPRAYING_FETCH_ERROR = "Error while fetching spraying";
  static MINIMUM_ADD_QUANTITY_ERROR =
    "Please add minimum one quantity for the selected product";
  static SPRAYING_UPDATE_SUCCESS = "Spraying detail updated successfully";
  static SPRAYING_UPDATE_ERROR = "Error while updating spraying details";
  static VALID_ACERAGE_ERROR = "Please Enter valid Acreage/Litres/Bigha value";
  static S3_PRODUCT_RECEIPTS_DIRECTORY = "product_receipts/";
  static DUPLICATE_SCANNED = "Product already selected";
  static PRODUCT_NOTFOUND = "Product not found";
}


export class UnimartConstants {
  static NO_USER_FOUND = "No User found";
  static NO_TRIPS_SUMMARY_FOUND = "No trips summary found";
  static NO_TRIPS_FOUND_FOR_USER = "No trips found for this user";
  static USER_LOCATION_NOT_FOUND = "User location not found";
  static FETCH_USER_ERROR = "Error while fetching user details";
  static USER_CREATE_SUCCESS = "User created successfully";
  static USER_UPDATE_SUCCESS = "User updated successfully";
  static USER_UPDATE_ERROR = "Error while updating user";
  static USER_CREATE_ERROR = "Error while creating user";
  static OUTLET_ERROR = "Please select Unimart Outlet";
  static TRUEPOS_USER_ERROR = "Please select TruePos User";
  static FILE_UPLOAD_SUCCESS = "File uploaded successfully";
  static FILE_UPLOAD_ERROR = "Error while uploading the file";
  static FILE_CHOOSE_INTERNET_ERROR =
    "Error while uploading the file. Please check your Internet Connection";
  static FILE_SIZE_ERROR = "File should be less than 2 MB";
  static FILE_FORMAT_ERROR = "Invalid File type";
  static OUTLET_FETCH_ERROR = "Error while fetching Outlet";
  static OUTLET_CREATE_SUCCESS = "Outlet Created Successfully";
  static OUTLET_CREATE_ERROR = "Error while creating Outlet";
  static OUTLET_UPDATE_SUCCESS = "Outlet Updated Successfully";
  static OUTLET_UPDATE_ERROR = "Error while updating Outlet";
  static DELETE_FAIL = "Error while deactivating user";
  static DELETE_ACTIVE_FAIL = "Error while activating user";
  static USER_ACTIVE_STATUS_SUCCESS = "User activated successfully";
  static USER_INACTIVE_STATUS_SUCCESS = "User deactivated successfully";
  static USER_SYNC_SUCCESS = "Users sync successfully";
  static USER_SYNC_ERROR = "Error while Users Sync";

  static S3_DOCUMENTS_DIRECTORIES = [
    // {
    //     path: 'users_kyc_documents/aadhaar/',
    //     title: 'Upload Aadhaar Card',
    //     label: 'Aadhaar Card',
    //     isValidated: {
    //         key: 'isAdhaarCardValidated',
    //         value: false,
    //     }
    // },
    {
      path: "users_kyc_documents/pancard/",
      title: "Upload PAN Card",
      label: "PAN Card",
      isValidated: {
        key: "isPanCardValidated",
        value: false,
      },
    },
  ];
}

export class NoticeBoardConstants {
  static TARGET_ADD_SUCCESS = "Target created successfully";
  static ERROR_TARGET_ADD = "Error while adding target";
  static SELECT_END_DATE_ERROR = "Please select end date";
  static SELECT_DATE_ERROR = "Please select date";
  static FILE_FORMAT_ERROR = "Invalid File type";
  static FILE_UPLOAD_SUCCESS = "File uploaded successfully";
  static FILE_UPLOAD_ERROR = "Error while uploading the file";
  static FILE_CHOOSE_INTERNET_ERROR =
    "Error while uploading the file. Please check your Internet Connection";
  static ANNOUNCEMENT_ADD_SUCCESS = "Announcement added successfully";
  static ERROR_ANNOUNCEMENT_ADD = "Error while adding announcement";
}

export class FarmerConstants {
  static NO_SOIL_MOISTURE = "Soil Moisture not found";
  static FARMER_EXPENSE_TYPE = "Please select expense type";
  static EXPENSE_SUCCESS = "Expense added successfully";
  static EXPENSE_DELETE_SUCCESS = "Expense deleted successfully";
  static EXPENSE_UPDATE_SUCCESS = "Expense updated successfully";
  static EXPENSE_ERROR = "Error while adding expense";
  static EXPENSE_DELETE_ERROR = "Error while deleting expense";
  static EXPENSE_UPDATE_ERROR = "Error while updating expense";
  static SELECT_YEAR = "Please select year";
  static SELECT_MONTH = "Please select month";
  static ENTER_AMOUNT = "Please enter expense amount";
  static SELECT_CROP = "Please select crop";
  static ENTER_TOTAL_YIELD = "Please enter total yields count";
  static FARMER_FAIL = "Farmer Not Found";
  static FARMER_GUID_FAIL = "Farmer with this Guid Not Found";
  static LOGO_IMAGE = "https://in.uplonline.com/images/logo.png";
  static FARMER_CREATE_SUCCESS = "Farmer created successfully";
  static FARMER_UPDATE_SUCCESS = "Farmer Updated Successfully";
  static FARMER_LAND_CREATE_SUCCESS = "Land Added Successfully";
  static FARMER_LAND_CREATE_ERROR = "Error while adding land";
  static ENTER_LAND_ERROR = "Enter Land Name";
  static ENTER_VILLAGE_ERROR = "Enter Village";
  static MAX_ADD_ERROR = "You already have added 10 lands";
  static FARMER_UPDATE_ERROR = "Error while updating farmer";
  static FARMER_CREATE_ERROR = "Error while creating farmer";
  static SPRAYING_FAIL = "No Spraying Records Found";
  static SPRAYING_LOCATION_FAIL = "No Spraying path found on the land";
  static FARMER_NDVI_LAND = "NDVI will be calculated for this farmer land";
  static FARMER_NDVI_LAND_ERROR = "Error while calculating NDVI";
  static SELECT_VALID_DATE = "Please select Start and End Date";
  static FARMER_BOOKING_CREATED_SUCCESS = "Booking created successfully";
  static FARMER_BOOKING_CREATED_FAIL = "Error while creating Booking";
  static S3_FARMER_PROFILE_PICTURE = "farmer_profile_pictures/";
  static FILE_UPLOAD_SUCCESS = "Profile Image uploaded successfully";
  static FARM_FILE_UPLOAD_SUCCESS = "Image uploaded successfully";
  static FILE_UPLOAD_ERROR = "Error while uploading the Image";
  static FILE_FORMAT_ERROR =
    "Invalid File format. Please upload Image in PNG, JPG and JPEG format only";
  static FILE_CHOOSE_INTERNET_ERROR =
    "Error while uploading the Image. Please check your Internet Connection";
  static FARMER_FEEDBACK_SUCCESS = "Feedback Submitted Successfully";
  static FARMER_FEEDBACK_ERROR = "Error while submitting Feedback";
  static FEATURE_COMMING_SOON = "Feature coming soon...";
  static SPEAK_NOW = "Speak Now...";
  static CLICK_ON_MIC_TO_START_SPEAKING = "Click on the Mic and start speaking";
  static SELECT_STATE = "Please select State";
  static SELECT_DISTRICT = "Please select District";
  static SELECT_TEHSIL = "Please select Tehsil";
  static SELECT_VILLAGE = "Please select Village";
  static ENTER_VILLAGE = "Please enter Village Name";
  static MOBILE_GPS_CHECK =
    "Please enable your mobile GPS location or Allow AFS to use your device location";
  static KAVACH_CANCELLED = "Kavach cancelled successfully";
  static KAVACH_CANCELLED_ERROR = "Error while cancelling Kavach";
  static S3_DOCUMENTS_DIRECTORIES = {
    framer_farm: {
      path: "farmer_farm_images/",
      title: "Upload Farm Image",
    },
  };
  static NDVI = [
    {
      mobile: "9462553203",
      className: "offer-default",
      date: "2018-06-02",
      image: "assets/img/NDVI/9462553203_vikram_20180602.png",
    },
    {
      mobile: "9462553203",
      className: "offer-danger",
      date: "2018-06-17",
      image: "assets/img/NDVI/9462553203_vikram_20180617.png",
    },
    {
      mobile: "9462553203",
      className: "offer-success",
      date: "2018-07-02",
      image: "assets/img/NDVI/9462553203_vikram_20180702.png",
    },
    {
      mobile: "9462553203",
      className: "offer-primary",
      date: "2018-07-27",
      image: "assets/img/NDVI/9462553203_vikram_20180727.png",
    },
    {
      mobile: "9462553203",
      className: "offer-info",
      date: "2018-08-06",
      image: "assets/img/NDVI/9462553203_vikram_20180806.png",
    },
    {
      mobile: "9462553203",
      className: "offer-warning",
      date: "2018-08-21",
      image: "assets/img/NDVI/9462553203_vikram_20180821.png",
    },
    {
      mobile: "9462553203",
      className: "offer-danger",
      date: "2018-08-26",
      image: "assets/img/NDVI/9462553203_vikram_20180826.png",
    },
    {
      mobile: "9462553203",
      className: "offer-default",
      date: "2018-09-05",
      image: "assets/img/NDVI/9462553203_vikram_20180905.png",
    },

    //9983343690 Servent
    {
      mobile: "9983343690",
      className: "offer-success",
      date: "2018-06-02",
      image: "assets/img/NDVI/9983343690_servant_20180602.png",
    },
    {
      mobile: "9983343690",
      className: "offer-primary",
      date: "2018-06-17",
      image: "assets/img/NDVI/9983343690_servant_20180617.png",
    },
    {
      mobile: "9983343690",
      className: "offer-info",
      date: "2018-07-02",
      image: "assets/img/NDVI/9983343690_servant_20180702.png",
    },
    {
      mobile: "9983343690",
      className: "offer-warning",
      date: "2018-07-17",
      image: "assets/img/NDVI/9983343690_servant_20180717.png",
    },
    {
      mobile: "9983343690",
      className: "offer-danger",
      date: "2018-08-01",
      image: "assets/img/NDVI/9983343690_servant_20180801.png",
    },
    {
      mobile: "9983343690",
      className: "offer-default",
      date: "2018-08-16",
      image: "assets/img/NDVI/9983343690_servant_20180816.png",
    },
    {
      mobile: "9983343690",
      className: "offer-success",
      date: "2018-08-26",
      image: "assets/img/NDVI/9983343690_servant_20180826.png",
    },
    {
      mobile: "9983343690",
      className: "offer-primary",
      date: "2018-09-05",
      image: "assets/img/NDVI/9983343690_servant_20180905.png",
    },

    //Kulvir 9992383044
    {
      mobile: "9992383044",
      className: "offer-success",
      date: "2018-06-02",
      image: "assets/img/NDVI/20180602.png",
    },
    {
      mobile: "9992383044",
      className: "offer-primary",
      date: "2018-06-17",
      image: "assets/img/NDVI/20180617.png",
    },
    {
      mobile: "9992383044",
      className: "offer-info",
      date: "2018-07-02",
      image: "assets/img/NDVI/20180702.png",
    },
    {
      mobile: "9992383044",
      className: "offer-warning",
      date: "2018-07-17",
      image: "assets/img/NDVI/20180717.png",
    },
    {
      mobile: "9992383044",
      className: "offer-danger",
      date: "2018-08-01",
      image: "assets/img/NDVI/20180801.png",
    },
    {
      mobile: "9992383044",
      className: "offer-default",
      date: "2018-08-16",
      image: "assets/img/NDVI/20180816.png",
    },
    {
      mobile: "9992383044",
      className: "offer-success",
      date: "2018-09-05",
      image: "assets/img/NDVI/20180905.png",
    },

    //Pratap Singh
    {
      mobile: "9813216115",
      className: "offer-success",
      date: "2018-10-20",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/10/20/0/0eb91aee80d145dbb2b3ac49d9650d4d.jpg",
    },
    {
      mobile: "9813216115",
      className: "offer-success",
      date: "2018-10-10",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/10/10/0/0eb91aee80d145dbb2b3ac49d9650d4d.jpg",
    },
    {
      mobile: "9813216115",
      className: "offer-success",
      date: "2018-09-30",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/9/30/0/0eb91aee80d145dbb2b3ac49d9650d4d.jpg",
    },
    {
      mobile: "9813216115",
      className: "offer-success",
      date: "2017-10-30",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2017/10/30/0/0eb91aee80d145dbb2b3ac49d9650d4d.jpg",
    },
    {
      mobile: "9813216115",
      className: "offer-success",
      date: "2017-10-10",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2017/10/10/0/0eb91aee80d145dbb2b3ac49d9650d4d.jpg",
    },

    //SUKH
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-10-20",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/10/20/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-10-10",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/10/10/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-09-30",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/9/30/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-09-12",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/9/12/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-08-26",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/8/26/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-08-11",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/8/11/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-07-29",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/7/29/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992763427",
      className: "offer-success",
      date: "2018-07-12",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/7/12/0/37f12233-9568-11e8-84d8-0247d2e0668e.jpg",
    },

    //RANVIR
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-10-20",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/10/20/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-10-10",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/10/10/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-09-30",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/9/30/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-09-12",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/9/12/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-08-26",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/8/26/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-08-11",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/8/11/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-07-29",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/7/29/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
    {
      mobile: "9992672223",
      className: "offer-success",
      date: "2018-07-12",
      image:
        "https://s3.eu-central-1.amazonaws.com/afs-rs-ndvi/satellite-images/sentinel/tiles/43/R/EN/2018/7/12/0/37efe7ac-9568-11e8-84d8-0247d2e0668e.jpg",
    },
  ];
  static MANIFESTJSON = {
    name: "UPL-Farmer",
    short_name: "UPL-Farmer",
    theme_color: "#1976d2",
    background_color: "#fafafa",
    display: "standalone",
    scope: "",
    start_url: "",
    icons: [
      {
        src: "assets/icons/icon-72x72.png",
        sizes: "72x72",
        type: "image/png",
      },
      {
        src: "assets/icons/icon-96x96.png",
        sizes: "96x96",
        type: "image/png",
      },
      {
        src: "assets/icons/icon-128x128.png",
        sizes: "128x128",
        type: "image/png",
      },
      {
        src: "assets/icons/icon-144x144.png",
        sizes: "144x144",
        type: "image/png",
      },
      {
        src: "assets/icons/icon-152x152.png",
        sizes: "152x152",
        type: "image/png",
      },
      {
        src: "assets/icons/icon-192x192.png",
        sizes: "192x192",
        type: "image/png",
      },
    ],
  };
}

export class UserRoleConstants {
  static ADMIN = "admin";
  static SUPER_ADMIN = "superadmin";
  // static BDO = 28;
  static BDO = "bdo";
  static BACK_OFFICE = "backoffice";
  static BACK_OFFICE_FULL_ACCESS = "sweta.sipuram@uniphos.com";
  static UTKARSH_USER = "utkarsh";
  static RS_USER = "rsupl";
  static TRAINER = "upl-afs-trainer";
  static SUPPORT = "afs-support";
  static CALL_CENTER_USER = "afs-callcenter";
  static SALE_USER = "afs-sales";
  static AFS_BI_USER = "afs-bi";
  static AFS_BI_USER_FIRMWARE = "afs-bi-firmware";
  static AKC_USER = "afs-akc";
  static NURTURE_METRICS_USER = "nurture.metric";
  static SPECIFIC_USER = "amit.kumar1@upl-ltd.com";
  static NURTURE_RETAIL_USER = "Swalsales";
  static RETAILER_USER = "retailer";
  static ROLE_AKC = "role_akc";
  static POINT_CREDIT_MENU = "POINT_CREDIT_MENU";
  static ROLE_SUPERADMIN = "role_superadmin";
  static ROLE_SUPERADMIN_WITH_CAMPAIGN = "role_superadmin_with_campaign";
  static ROLE_L1_TECH_PRIMARY_MENU = "role_l1_tech_primary";
  static ROLE_L1_TECH_SECONDRY_MENU = "role_l1_tech_secondary";
  static ROLE_CARE_TEAM = "role_care_team";
  static ROLE_L1_NON_TECH = "role_l1_non_tech";
  static L0_AGENT_PRIMARY = "l0_agent_primary";
  static L0_AGENT_SECONDRY = "l0_agent_secondary";
  static L0_AGENT_BASIC = "l0_agent_basic";
  static ROLE_CAMPAIGN_MANAGEMENT = "ROLE_CAMPAIGN_MANAGEMENT";
  static ROLE_PICTURE_VERIFICATION = "ROLE_PICTURE_VERIFICATION";
  static ROLE_PRIMARY = "ROLE_PRIMARY";
}

export class HelpVideoConstants {
  static UPDATE_TICKET_STATUS_SUCCESS = "Status updated successfully";
  static UPDATE_TICKET_STATUS_ERROR = "Error while updating Status";
  static NO_MEDIA_FOUND = "No media found for this request";
  static helpVideos = {
    mdm: [
      {
        title: "Users",
        subtitle: "Adding BDO, FO, Operators",
        content:
          "The purpose of this video is to give you an example of how to add BDO, FO and Operators.",
        url: "http://bit.ly/upl-users",
      },
      {
        title: "Machines",
        subtitle: "A video of Machines Tab overview",
        content:
          "The purpose of this video is to give you example for how to add machines.",
        url: "http://bit.ly/upl-machines",
      },
      {
        title: "Geographies",
        subtitle: "A video of Geographies Tab overview",
        content:
          "The purpose of this video is to give you example for how to add geographies.",
        url: "http://bit.ly/upl-geographies",
      },
      {
        title: "Farmer Booking Service",
        subtitle: "A video of Farmer Booking Service overview",
        content:
          "The purpose of this video is to give you example for how to send farmer booking service." +
          "<br>" +
          '<a href="/afs-admin/booking" target="_blank">View booking service page.</a>',
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Farmer_Booking_Service.webm",
      },
    ],
    android: [
      {
        title: "Location Sync",
        subtitle: "A video of Spraying Location Sync overview",
        content:
          "The purpose of this video is to give you an example of how to sync spraying location.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Location_Sync.mp4",
      },
      {
        title: "Cancel & No Spraying Functionality",
        subtitle: "A video of Cancel & No Spraying functionality",
        content:
          "The purpose of this video is to give you an example to perform Cancel & No Spraying.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/No_Spraying_Functionality.mp4",
      },
      {
        title: "Geo Fencing",
        subtitle: "A video of adding Farmer Land overview",
        content:
          "The purpose of this video is to give you an example of adding land map.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Add_Land_Map.mp4",
      },
      {
        title: "Fuel & Maintenance Expenses",
        subtitle: "A video of Expense overview",
        content:
          "The purpose of this video is to give you an example of maintaining expenses.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Expenses.mp4",
      },
      {
        title: "BDO Login",
        subtitle: "BDO Login overview",
        content:
          "The purpose of this video is to give you an overview of BDO login.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/BDO_Login+(Upcoming_functionality).mp4",
      },
      {
        title: "FO Login",
        subtitle: "FO Login overview",
        content:
          "The purpose of this video is to give you an you an overview of FO login.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/FO_Login+(Upcoming_functionality).mp4",
      },
      {
        title: "Operator Login",
        subtitle: "Operator Login overview",
        content:
          "The purpose of this video is to give you an you an overview of Operator login.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Operator_Login_Overview+(upcoming_functionality).mp4",
      },
      {
        title: "Booking Service Request",
        subtitle: "A video of Booking Service Request overview",
        content:
          "The purpose of this video is to give you an understanding of performing Spraying with farmer booking request.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Booking_Service+(Upcoming_functionality).mp4",
      },
      {
        title: "Reject Booking",
        subtitle: "A video of Reject Booking overview",
        content:
          "The purpose of this video is to give you an understanding of rejecting a booking request of a Farmer.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Reject_Booking+(Upcoming_functionality).mp4",
      },
      {
        title: "Barcode Product Scanning",
        subtitle: "A video of scanning products using Barcode overview",
        content:
          "The purpose of this video is to give you an example for how to scan the particular products.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Barcode_Integrated_Application.mp4",
      },
      {
        title: "Add Land Map",
        subtitle: "A video of adding land by Map overview",
        content:
          "The purpose of this video is to give you an example for adding lands using the Google Map.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Add_Land_Map.mp4",
      },
      {
        title: "Adding Expense",
        subtitle: "A video of adding expense by Operator overview",
        content:
          "The purpose of this video is to give you an example for adding expense by the Operator for a farmer.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Expenses.mp4",
      },
      {
        title: "Farmer Registration",
        subtitle: "A video of registering a farmer overview",
        content:
          "The purpose of this video is to give you an example for registering a farmer by filling the form.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Farmer+Registration.mp4",
      },
      {
        title: "Shaktiman Technincian",
        subtitle: "Shaktiman Technincian Login overview",
        content:
          "The purpose of this video is to give you an overview of Shaktiman Technincian Login.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Saktiman_Technician_Application.mp4",
      },
      {
        title: "Shaktiman Technincian",
        subtitle: "Shaktiman Technincian Login overview",
        content:
          "The purpose of this video is to give you an overview of Shaktiman Technincian Login.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-operations/MDM_Help_Videos/Saktiman_Technician_Application.mp4",
      },
      {
        title: "Spraying Under Acceptance Tab",
        subtitle: "Spraying under acceptance tab overview",
        content:
          "The purpose of this video is to give you an overview of spraying under acceptance tab.",
        url: "https://s3.ap-south-1.amazonaws.com/afs-static-content/Android_Help_Videos/Spraying+from+Acceptance+tab.mp4",
      },
    ],
  };
}

export class RemoteSensingConstants {
  static COLORS = [
    "#63b598",
    "#ce7d78",
    "#ea9e70",
    "#a48a9e",
    "#c6e1e8",
    "#648177",
    "#0d5ac1",
    "#f205e6",
    "#1c0365",
    "#14a9ad",
    "#4ca2f9",
    "#a4e43f",
    "#d298e2",
    "#6119d0",
    "#d2737d",
    "#c0a43c",
    "#f2510e",
    "#651be6",
    "#79806e",
    "#61da5e",
    "#cd2f00",
    "#9348af",
    "#01ac53",
    "#c5a4fb",
    "#996635",
    "#b11573",
    "#4bb473",
    "#75d89e",
    "#2f3f94",
    "#2f7b99",
    "#da967d",
    "#34891f",
    "#b0d87b",
    "#ca4751",
    "#7e50a8",
    "#c4d647",
    "#e0eeb8",
    "#11dec1",
    "#289812",
    "#566ca0",
    "#ffdbe1",
    "#2f1179",
    "#935b6d",
    "#916988",
    "#513d98",
    "#aead3a",
    "#9e6d71",
    "#4b5bdc",
    "#0cd36d",
    "#250662",
    "#cb5bea",
    "#228916",
    "#ac3e1b",
    "#df514a",
    "#539397",
    "#880977",
    "#f697c1",
    "#ba96ce",
    "#679c9d",
    "#c6c42c",
    "#5d2c52",
    "#48b41b",
    "#e1cf3b",
    "#5be4f0",
    "#57c4d8",
    "#a4d17a",
    "#225b8",
    "#be608b",
    "#96b00c",
    "#088baf",
    "#f158bf",
    "#e145ba",
    "#ee91e3",
    "#05d371",
    "#5426e0",
    "#4834d0",
    "#802234",
    "#6749e8",
    "#0971f0",
    "#8fb413",
    "#b2b4f0",
    "#c3c89d",
    "#c9a941",
    "#41d158",
    "#fb21a3",
    "#51aed9",
    "#5bb32d",
    "#807fb",
    "#21538e",
    "#89d534",
    "#d36647",
    "#7fb411",
    "#0023b8",
    "#3b8c2a",
    "#986b53",
    "#f50422",
    "#983f7a",
    "#ea24a3",
    "#79352c",
    "#521250",
    "#c79ed2",
    "#d6dd92",
    "#e33e52",
    "#b2be57",
    "#fa06ec",
    "#1bb699",
    "#6b2e5f",
    "#64820f",
    "#1c271",
    "#21538e",
    "#89d534",
    "#d36647",
    "#7fb411",
    "#0023b8",
    "#3b8c2a",
    "#986b53",
    "#f50422",
    "#983f7a",
    "#ea24a3",
    "#79352c",
    "#521250",
    "#c79ed2",
    "#d6dd92",
    "#e33e52",
    "#b2be57",
    "#fa06ec",
    "#1bb699",
    "#6b2e5f",
    "#64820f",
    "#1c271",
    "#9cb64a",
    "#996c48",
    "#9ab9b7",
    "#06e052",
    "#e3a481",
    "#0eb621",
    "#fc458e",
    "#b2db15",
    "#aa226d",
    "#792ed8",
    "#73872a",
    "#520d3a",
    "#cefcb8",
    "#a5b3d9",
    "#7d1d85",
    "#c4fd57",
    "#f1ae16",
    "#8fe22a",
    "#ef6e3c",
    "#243eeb",
    "#1dc18",
    "#dd93fd",
    "#3f8473",
    "#e7dbce",
    "#421f79",
    "#7a3d93",
    "#635f6d",
    "#93f2d7",
    "#9b5c2a",
    "#15b9ee",
    "#0f5997",
    "#409188",
    "#911e20",
    "#1350ce",
    "#10e5b1",
    "#fff4d7",
    "#cb2582",
    "#ce00be",
    "#32d5d6",
    "#17232",
    "#608572",
    "#c79bc2",
    "#00f87c",
    "#77772a",
    "#6995ba",
    "#fc6b57",
    "#f07815",
    "#8fd883",
    "#060e27",
    "#96e591",
    "#21d52e",
    "#d00043",
    "#b47162",
    "#1ec227",
    "#4f0f6f",
    "#1d1d58",
    "#947002",
    "#bde052",
    "#e08c56",
    "#28fcfd",
    "#bb09b",
    "#36486a",
    "#d02e29",
    "#1ae6db",
    "#3e464c",
    "#a84a8f",
    "#911e7e",
    "#3f16d9",
    "#0f525f",
    "#ac7c0a",
    "#b4c086",
    "#c9d730",
    "#30cc49",
    "#3d6751",
    "#fb4c03",
    "#640fc1",
    "#62c03e",
    "#d3493a",
    "#88aa0b",
    "#406df9",
    "#615af0",
    "#4be47",
    "#2a3434",
    "#4a543f",
    "#79bca0",
    "#a8b8d4",
    "#00efd4",
    "#7ad236",
    "#7260d8",
    "#1deaa7",
    "#06f43a",
    "#823c59",
    "#e3d94c",
    "#dc1c06",
    "#f53b2a",
    "#b46238",
    "#2dfff6",
    "#a82b89",
    "#1a8011",
    "#436a9f",
    "#1a806a",
    "#4cf09d",
    "#c188a2",
    "#67eb4b",
    "#b308d3",
    "#fc7e41",
    "#af3101",
    "#ff065",
    "#71b1f4",
    "#a2f8a5",
    "#e23dd0",
    "#d3486d",
    "#00f7f9",
    "#474893",
    "#3cec35",
    "#1c65cb",
    "#5d1d0c",
    "#2d7d2a",
    "#ff3420",
    "#5cdd87",
    "#a259a4",
    "#e4ac44",
    "#1bede6",
    "#8798a4",
    "#d7790f",
    "#b2c24f",
    "#de73c2",
    "#d70a9c",
    "#25b67",
    "#88e9b8",
    "#c2b0e2",
    "#86e98f",
    "#ae90e2",
    "#1a806b",
    "#436a9e",
    "#0ec0ff",
    "#f812b3",
    "#b17fc9",
    "#8d6c2f",
    "#d3277a",
    "#2ca1ae",
    "#9685eb",
    "#8a96c6",
    "#dba2e6",
    "#76fc1b",
    "#608fa4",
    "#20f6ba",
    "#07d7f6",
    "#dce77a",
    "#77ecca",
  ];
  static POLYLINE_COLOR = ["#2EFF20", "#2EFF20"];
  static STATES_LAYER =
    "https://s3.ap-south-1.amazonaws.com/afs-geography-geojson-bucket/gadm36_IND_1.json";
  static DISTRICTS_LAYER =
    "https://s3.ap-south-1.amazonaws.com/afs-geography-geojson-bucket/gadm36_IND_2.json";
  static TEHSIL_LAYER =
    "https://s3.ap-south-1.amazonaws.com/afs-geography-geojson-bucket/gadm36_IND_3.json";
  static NO_FARMER_NDVI = "Farmer land NDVI not found";
  static NO_FARMER_SOIL_MOISTURE = "Farmer land Soil Moisture not found";
  static ENTER_VALID_MOBILE = "Please enter valid Mobile Number";
}

export const DEFAULT_CATEGORY_TYPE_IDS = {
  77: "BEFORE_AERATION_EVENT_KHARIF_24",
  90: "BEFORE_AERATION_EVENT_RABI_24"
};

export class ContactUsConstants {
  static contactEmail = "mdm-support@nurture.farm";
}

export const AERATION_EVENTS = [
  "AWD_DRY_EVENT_1_KHARIF_22",
  "AWD_DRY_EVENT_2_KHARIF_22",
  "AWD_DRY_EVENT_3_KHARIF_22",
  "AWD_DRY_EVENT_1_RABI_22",
  "AWD_DRY_EVENT_2_RABI_22",
  "AWD_DRY_EVENT_3_RABI_22",
  "AWD_FARMER_LAND_RECORD_PHOTO_RABI_22",

  "AWD_DRY_EVENT_1_KHARIF_23",
  "AWD_DRY_EVENT_2_KHARIF_23",
  "AWD_DRY_EVENT_3_KHARIF_23",
  "AWD_DRY_EVENT_1_RABI_23",
  "AWD_DRY_EVENT_2_RABI_23",
  "AWD_DRY_EVENT_3_RABI_23",
  "AWD_FARMER_LAND_RECORD_PHOTO_RABI_23",
  "AWD_FARMER_STAKEHOLDER_MEET_PHOTO_RABI_2023",

  "AWD_DRY_EVENT_1_KHARIF_24",
  "AWD_DRY_EVENT_2_KHARIF_24",
  "AWD_DRY_EVENT_3_KHARIF_24",
  "AWD_CENTRE_OF_BUND_KHARIF_24",
  "AWD_FARMER_GAINEXA_PHOTO_KHARIF_24",
  "AWD_FARMER_LAND_RECORD_PHOTO_KHARIF_24",
  "AWD_FARMER_STAKEHOLDER_MEET_PHOTO_KHARIF_2024",

  "AWD_DRY_EVENT_1_RABI_24",
  "AWD_DRY_EVENT_2_RABI_24",
  "AWD_SIGNUP_EVENT_RABI_24",
  "GEOTAGGING_RABI_24",
  "LANDOWNER_DETAILS_RABI_24",
  "AWD_DRY_EVENT_3_RABI_24",
  "AWD_CENTRE_OF_BUND_RABI_24",
  "AWD_FARMER_GAINEXA_PHOTO_RABI_24",
  "AWD_FARMER_LAND_RECORD_PHOTO_RABI_24",
  "AWD_CULTIVATION_LOG_RABI_2024",
  "AWD_GEOFENCE_VIEW_RABI_2024",
  "AWD_FARMER_STAKEHOLDER_MEET_PHOTO_RABI_2024",

  "SUGARCANE_PROGRAM_LANDOWNER_DETAILS_24",
  "SUGARCANE_PROGRAM_SIGNUP_EVENT_24",
  "SUGARCANE_FARMER_STAKEHOLDER_MEET_PHOTO_2024",
  "SUGARCANE_SOIL_APPLICATION_2024",
  "SUGARCANE_LAND_PREPARATION_2024",
  "SUGARCANE_FERTILIZER_APPLICATION_2024",
  "SUGARCANE_WATER_MANAGEMENT_2024",

];

export const SEASON_IDS_BASED_ON_EVENT_IDS = [
  { eventId: 0, seasonId: 0, type: 'awd' },

  { eventId: 1, seasonId: 212, type: 'awd' },
  { eventId: 2, seasonId: 212, type: 'awd' },
  { eventId: 3, seasonId: 212, type: 'awd' },
  { eventId: 4, seasonId: 212, type: 'awd' },
  { eventId: 5, seasonId: 212, type: 'awd' },
  { eventId: 6, seasonId: 212, type: 'awd' },
  { eventId: 7, seasonId: 212, type: 'awd' },
  { eventId: 8, seasonId: 212, type: 'awd' },
  { eventId: 9, seasonId: 212, type: 'awd' },
  { eventId: 10, seasonId: 212, type: 'awd' },
  { eventId: 11, seasonId: 212, type: 'awd' },
  { eventId: 12, seasonId: 212, type: 'awd' },
  { eventId: 13, seasonId: 212, type: 'awd' },
  { eventId: 14, seasonId: 212, type: 'awd' },
  { eventId: 15, seasonId: 212, type: 'awd' },
  { eventId: 16, seasonId: 212, type: 'awd' },

  { eventId: 17, seasonId: 221, type: 'awd' },
  { eventId: 18, seasonId: 221, type: 'awd' },
  { eventId: 19, seasonId: 221, type: 'awd' },
  { eventId: 20, seasonId: 221, type: 'awd' },
  { eventId: 21, seasonId: 221, type: 'awd' },
  { eventId: 22, seasonId: 221, type: 'awd' },
  { eventId: 23, seasonId: 221, type: 'awd' },
  { eventId: 24, seasonId: 221, type: 'awd' },
  { eventId: 25, seasonId: 221, type: 'awd' },
  { eventId: 26, seasonId: 221, type: 'awd' },
  { eventId: 27, seasonId: 221, type: 'awd' },
  { eventId: 28, seasonId: 221, type: 'awd' },
  { eventId: 29, seasonId: 221, type: 'awd' },
  { eventId: 30, seasonId: 221, type: 'awd' },
  { eventId: 31, seasonId: 221, type: 'awd' },
  { eventId: 32, seasonId: 221, type: 'awd' },
  { eventId: 33, seasonId: 221, type: 'awd' },
  { eventId: 34, seasonId: 221, type: 'awd' },

  { eventId: 41, seasonId: 222, type: 'awd' },
  { eventId: 42, seasonId: 222, type: 'awd' },
  { eventId: 43, seasonId: 222, type: 'awd' },
  { eventId: 44, seasonId: 222, type: 'awd' },
  { eventId: 45, seasonId: 222, type: 'awd' },
  { eventId: 46, seasonId: 222, type: 'awd' },
  { eventId: 47, seasonId: 222, type: 'awd' },
  { eventId: 48, seasonId: 222, type: 'awd' },
  { eventId: 49, seasonId: 222, type: 'awd' },
  { eventId: 50, seasonId: 222, type: 'awd' },
  { eventId: 51, seasonId: 222, type: 'awd' },
  { eventId: 52, seasonId: 222, type: 'awd' },

  { eventId: 61, seasonId: 232, type: 'awd' },
  { eventId: 62, seasonId: 232, type: 'awd' },
  { eventId: 63, seasonId: 232, type: 'awd' },
  { eventId: 64, seasonId: 232, type: 'awd' },
  { eventId: 65, seasonId: 232, type: 'awd' },
  { eventId: 66, seasonId: 232, type: 'awd' },
  { eventId: 67, seasonId: 232, type: 'awd' },
  { eventId: 68, seasonId: 232, type: 'awd' },
  { eventId: 69, seasonId: 232, type: 'awd' },
  { eventId: 70, seasonId: 232, type: 'awd' },
  { eventId: 71, seasonId: 232, type: 'awd' },
  { eventId: 72, seasonId: 232, type: 'awd' },
  { eventId: 73, seasonId: 232, type: 'awd' },

  { eventId: 77, seasonId: 241, type: 'awd' },
  { eventId: 78, seasonId: 241, type: 'awd' },
  { eventId: 79, seasonId: 241, type: 'awd' },
  { eventId: 81, seasonId: 241, type: 'awd' },
  { eventId: 83, seasonId: 241, type: 'awd' },
  { eventId: 84, seasonId: 241, type: 'awd' },
  { eventId: 85, seasonId: 241, type: 'awd' },
  { eventId: 86, seasonId: 241, type: 'awd' },
  { eventId: 89, seasonId: 241, type: 'awd' },


  { eventId: 90, seasonId: 242, type: 'awd' },
  { eventId: 91, seasonId: 242, type: 'awd' },
  { eventId: 92, seasonId: 242, type: 'awd' },
  { eventId: 93, seasonId: 242, type: 'awd' },
  { eventId: 94, seasonId: 242, type: 'awd' },
  { eventId: 95, seasonId: 242, type: 'awd' },
  { eventId: 96, seasonId: 242, type: 'awd' },
  { eventId: 97, seasonId: 242, type: 'awd' },
  { eventId: 98, seasonId: 242, type: 'awd' },
  { eventId: 99, seasonId: 242, type: 'awd' },
  { eventId: 100, seasonId: 242, type: 'awd' },
  { eventId: 101, seasonId: 242, type: 'awd' },
  { eventId: 102, seasonId: 242, type: 'awd' },

  { eventId: 103, seasonId: 323, type: 'sugarcane' },
  { eventId: 104, seasonId: 323, type: 'sugarcane' },
  { eventId: 105, seasonId: 323, type: 'sugarcane' },
  { eventId: 106, seasonId: 323, type: 'sugarcane' },
  { eventId: 107, seasonId: 323, type: 'sugarcane' },
  { eventId: 108, seasonId: 323, type: 'sugarcane' },
  { eventId: 109, seasonId: 323, type: 'sugarcane' },
  { eventId: 110, seasonId: 323, type: 'sugarcane' },
];

export const ORDER_REJECT_REASONS = [
  "Partner unavailable",
  "Retailer unavailable",
  "Inventory not there",
  "Farmer delivery address too far",
];

export const BILL_DETAILS = [
  { name: "Product 1", qty: 3, total: 5000 },
  { name: "Product 2", qty: 3, total: 5000 },
];

export const ORDER_STATUSES = [
  { displayName: "NO_STATUS" },
  { displayName: "CREATED" },
  { displayName: "CONFIRMED" },
  { displayName: "DISPATCHED" },
  { displayName: "CANCELLED" },
  { displayName: "DELIVERED" },
  { displayName: "UNDELIVERED" },
  { displayName: "RETURN_INITIATED" },
  { displayName: "RETURN_SUCCESSFUL" },
  { displayName: "UNRECOGNIZED" },
];

export const ORDER_STATUS = {
  noStatus: "NO_STATUS",
  created: "CREATED",
  dispatched: "DISPATCHED",
  cancelled: "CANCELLED",
  delivered: "DELIVERED",
  confirmed: "CONFIRMED",
};

export const NON_CANCELLABLE_STATUSES = [
  "DISPATCHED",
  "DELIVERED",
  "UNRECOGNIZED",
  "RETURN_INITIATED",
  "RETURN_SUCCESSFUL",
  "UNDELIVERED",
];

export const CONFIRM_ORDER = { label: "Confirm Order", value: "CONFIRMED" };
export const CANCEL_ORDER = { label: "Cancel Order", value: "CANCELLED" };
